<template>
  <div class="box-table mb50">
    <section class="show-item">
      <div class="container mt-4">
        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Show Entries</label>
              <select class="form-select black" name="per_page" id="per_page" v-model="perPage">
                <option v-for="(page, index) in pageOptions" :key="index" :value="page">{{ page }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Search</label>
              <input type="text" name="search" id="search" class="form-control" placeholder="Search..." v-model="filter">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Table -->
    <b-table
      outlined
      ref="table"
      :items="fetchPositionPaper"
      :fields="fields"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      :empty-text="'There are no records to show'"
      :empty-filtered-text="'There are no records matching your request'"
      class="table-manage"
    >
      <!-- status -->
      <template v-slot:cell(status)="data">
        <div class="box-status approved" v-if="data.item.status == 'Approved'">{{ data.item.status }}</div>
        <div class="box-status reject" v-if="data.item.status == 'Rejected'">{{ data.item.status }}</div>
        <div class="box-status aprroval" v-if="data.item.status == 'Waiting Review'">{{ data.item.status }}</div>
      </template>
      <!-- Aciton -->
      <template v-slot:cell(action)="data">
        <div class="box-btn-action">
          <a :href="data.item.file" class="btn btn-icon" target="_blank">
            <span class="icon-ico-download"></span>
            Download
          </a>
        </div>
      </template>
    </b-table>
    <div class="filter-row">
      <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
      <div class="button-filter">
        <ul class="pagination pagination-rounded mb-0">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PositionPaperList",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  watch: {
    room() {
      this.$refs.table.refresh();
      this.getDetailRoom();
    },
  },
  data() {
    return {
      roomDetail: {},
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "country", label: "COUNTRY", sortable: true },
        // { key: "paper", label: "PAPER", sortable: true },
        { key: "status", label: "STATUS", sortable: true },
        { key: "upload_date", label: "UPLOAD DATE", sortable: true },
        'ACTION'
      ],
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  methods: {
    async fetchPositionPaper(ctx) {
      if (this.room.id === undefined) {
        this.totalRows = 0;
        return []
      } else {
        const promise = this.$axios.get(`/simulation/student/position_paper`, {
          params: {
            page: ctx.currentPage,
            per_page: ctx.perPage,
            search: ctx.filter,
            sortBy: ctx.sortBy,
            sortType: (ctx.sortDesc)? 'asc' : 'desc',
            roomId: this.room.id,
          }
        })

        // Must return a promise that resolves to an array of items
        return promise.then(response => {
          // Pluck the array of items off our axios response
          const items = response.data.data.data
          this.totalRows = response.data.data.meta.total
          // Must return an array of items or an empty array if an error occurred
          return items || []
        })
      }
    },
    getDetailRoom() {
      this.$axios.get(`/master/room/${this.room.id}`)
        .then(response => {
          this.roomDetail = response.data.data
        })
    },
    refreshTable() {
      this.$refs.table.refresh();
    }
  }
}
</script>

<style type="css" scoped>
.filter-row {
  padding: 0px 24px 0px 24px
}
</style>