<template>
  <div class="container">
    <div class="section-simulation">
      <div class="row">
        <div class="col-lg-6">
          <div class="parti-intro">
            <div class="d-flex">
              <div class="title-intro">Committe</div>
              <div class="mx-2">:</div>
              <div class="intro-parti-desc">
                <p class="font-medium">{{ header.committee }}</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="title-intro">Subject Topic</div>
              <div class="mx-2">:</div>
              <div class="intro-parti-desc">
                <p class="font-medium">{{ header.subject_topic }}</p>
              </div>
            </div>
            <div class="d-flex">
              <div class="title-intro">Country Present</div>
              <div class="mx-2">:</div>
              <div class="intro-parti-desc">
                <p class="font-medium">{{ header.country_present }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="parti-intro">
            <template v-if="roomDetail.link_zoom !== null && header.link_zoom !== 'https://null'">
              <div class="d-flex">
                <div class="title-intro">Zoom</div>
                <div class="mx-2">:</div>
                <div class="intro-parti-desc">
                  <p>
                    <a :href="roomDetail.link_zoom" target="_blank" class="link-wrap">{{ roomDetail.link_zoom }}</a>
                  </p>
                </div>
              </div>
              <template v-for="(meeting, index) in roomDetail.meeting_credentials">
                <div :key="index">
                  <div class="d-flex">
                    <div class="title-intro">Meeting ID</div>
                    <div class="mx-2">:</div>
                    <div class="intro-parti-desc">
                      <p>
                        {{ meeting.meeting_id }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex" :key="index">
                    <div class="title-intro">Meeting Password</div>
                    <div class="mx-2">:</div>
                    <div class="intro-parti-desc">
                      <p>
                        {{ meeting.password }}
                      </p>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <div class="d-flex" v-if="roomDetail.link_google_meet !== null && header.link_google_meet !== 'https://null'">
              <div class="title-intro">Google Meet</div>
              <div class="mx-2">:</div>
              <div class="intro-parti-desc">
                <p>
                  <a :href="roomDetail.link_google_meet" target="_blank" class="link-wrap">{{ roomDetail.link_google_meet }}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-candidate mb50 mh-2" v-if="currentState">
        <div class="bc-head justify-content-center">
          <div class="bch-ttl">CURRENT STATE OF AFFAIRS</div>
        </div>
        <div class="bc-content p30">
          <div class="state-ttl mb30" v-if="currentState.tab_active === 'Moderated Caucus'">
            {{ currentState.tab_active }}<br />
            <span style="font-size: 20px">{{ currentState.title }}</span>
          </div>
          <div class="state-ttl mb30" v-else-if="currentState.tab_active === 'Unmoderated Caucus'">
            {{ currentState.title }}
          </div>
          <div class="state-ttl mb30" v-else>
            {{ currentState.tab_active }}
          </div>

          <div class="box-flag" v-if="currentState.tab_active != 'Unmoderated Caucus'">
            <img :src="currentState.flag" style="max-width: 240px; border: 1px solid" class="img-fluid" alt="Flag">
            <p v-if="currentState.country !== null && currentState.country != ''">{{ currentState.country }}</p>
          </div>

          <div class="row" id="timer-debate" v-if="currentState.tab_active == 'Moderated Caucus' || currentState.tab_active == 'Unmoderated Caucus'">
            <div class="col-lg-10 offset-lg-1">
              <div class="progress mb30">
                <div class="progress-bar" role="progressbar" 
                  :style="{width: progress2}" aria-valuenow="50"
                  aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>

              <div class="time-indicator mb24">
                <span id="remaining2">
                  <template v-if="remaining2 !== null">
                    {{ remaining2 }}
                  </template>
                  <template v-else>
                    00:00
                  </template>
                </span> / {{ timeLimit2 }}
              </div>
            </div>
          </div>

          <div class="row" id="timer-speaker" v-if="currentState.tab_active != 'Unmoderated Caucus'">
            <div class="col-lg-10 offset-lg-1">
              <div class="progress mb30">
                <div class="progress-bar" role="progressbar" 
                  :style="{width: progress}" aria-valuenow="50"
                  aria-valuemin="0" aria-valuemax="100">
                </div>
              </div>

              <div class="time-indicator mb24">
                <span id="remaining">
                  <template v-if="remaining !== null">
                    {{ remaining }}
                  </template>
                  <template v-else>
                    00:00
                  </template>
                </span> / {{ timeLimit }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box-inner-simulation">
        <div class="box-simulation">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a href="#" class="nav-link slide-nav" id="gsl-tab" data-bs-toggle="tab" 
                data-bs-target="#rdv" type="button" role="tab" aria-controls="rdv" 
                :aria-selected="[tab == 'roll-call-vote'? true : false]"
                :class="{ active: tab == 'roll-call-vote' }"
                @click="tab = 'roll-call-vote'"
              > 
                Roll Call Vote
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a href="#" class="nav-link slide-nav" id="ss-tab" data-bs-toggle="tab" 
                data-bs-target="#ass" type="button" role="tab" aria-controls="ass" 
                :aria-selected="[tab == 'assignment'? true : false]"
                :class="{ active: tab == 'assignment' }"
                @click="tab = 'assignment'"
              >
                Assignment
              </a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" :class="{ active: tab == 'roll-call-vote', show: tab == 'roll-call-vote' }" id="gsl" role="tabpanel" aria-labelledby="gsl-tab">
              <roll-call-vote :room="room"></roll-call-vote>
            </div>
            <div class="tab-pane fade" :class="{ active: tab == 'assignment', show: tab == 'assignment' }" id="ss" role="tabpanel" aria-labelledby="ss-tab">
              <assignment :room="room"></assignment>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RollCallVote from './SimulationTabContent/RollCallVote.vue';
import Assignment from './SimulationTabContent/Assignment.vue';
import moment from "moment";

export default {
  name: "Simulation",
  components: {
    RollCallVote,
    Assignment
  },
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      roomDetail: {},
      header: {},
      tab: 'roll-call-vote',
      currentState: null,
      durHour: 1,
      durMinute: 0,
      durSecond: 0,
      minute: 1,
      second: 0,
      remaining: null,
      remaining2: null,
      counter: null,
      counter2: null
    }
  },
  computed: {
    timeLimit() {
      return moment().minute(this.minute).second(this.second).format('mm:ss')
    },
    progress() {
      if (this.remaining !== null && this.timeLimit !== null) {
        const currentMinute = moment(this.remaining, 'mm:ss').minute();
        const currentSecond = moment(this.remaining, 'mm:ss').second();
        const current = (currentMinute * 60) + currentSecond

        const endMinute = moment(this.timeLimit, 'mm:ss').minute();
        const endSecond = moment(this.timeLimit, 'mm:ss').second();
        const end = (endMinute * 60) + endSecond

        const progress = current / end * 100

        return `${progress}%`
      } else {
        return `0%`
      }
    },
    timeLimit2() {
      return moment().hour(this.durHour).minute(this.durMinute).second(this.durSecond).format('HH:mm:ss')
    },
    progress2() {
      if (this.remaining2 !== null && this.timeLimit2 !== null) {
        const currentHour = moment(this.remaining2, 'HH:mm:ss').hour();
        const currentMinute = moment(this.remaining2, 'HH:mm:ss').minute();
        const currentSecond = moment(this.remaining2, 'HH:mm:ss').second();
        const current = (currentHour * 60 * 60) + (currentMinute * 60) + currentSecond

        const endHour = moment(this.timeLimit2, 'HH:mm:ss').hour();
        const endMinute = moment(this.timeLimit2, 'HH:mm:ss').minute();
        const endSecond = moment(this.timeLimit2, 'HH:mm:ss').second();
        const end = (endHour * 60 * 60) + (endMinute * 60) + endSecond

        const progress = current / end * 100

        return `${progress}%`
      } else {
        return `0%`
      }
    }
  },
  watch: {
    room() {
      this.getHeader()
      this.getDetailRoom()

      window.Echo.channel(`current_state.${this.room.id}`)
      .listen('CurrentStateEvent', (e) => {
        console.log(e)
        this.getCurrentState()
      });
    }
  },
  mounted() {
    if (this.room.id !== undefined) {
      this.getHeader()
      this.getCurrentState()

      window.Echo.channel(`current_state.${this.room.id}`)
        .listen('CurrentStateEvent', (e) => {
          console.log(e)
          this.getCurrentState()
        });
    }
  },
  beforeDestroy() {
    window.Echo.leave(`current_state.${this.room.id}`) 
  },
  methods: {
    getHeader() {
      this.$axios.get(`/student/simulation/${this.room.id}/show`)
        .then(response => {
          this.header = response.data.data
        })
    },
    getDetailRoom() {
      this.$axios.get(`/master/room/${this.room.id}`)
        .then(response => {
          this.roomDetail = response.data.data
        })
    },
    getCurrentState() {
      this.$axios.get(`/student/simulation/${this.room.id}/get_current_state`)
        .then(response => {
          if (response.data.data.type === 'reset') {
            this.currentState = null
          } else {
            this.currentState = response.data.data
            this.durHour = response.data.data.duration_hours
            this.durMinute = response.data.data.duration_minutes
            this.durSecond = response.data.data.duration_seconds
            this.minute = response.data.data.minutes
            this.second = response.data.data.seconds

            if (this.currentState.tab_active != 'Unmoderated Caucus') {
              this.minute = response.data.data.minutes
              this.second = response.data.data.seconds

              if (response.data.data.type == 'start') {
                this.remaining = moment().minute(response.data.data.remaining_minutes).second(response.data.data.remaining_seconds).format('mm:ss')
                let count = this.remaining !== null ? this.remaining : moment(this.timeLimit, 'mm:ss').minute(0).second(0)
                clearInterval(this.counter)
                this.counter = setInterval(() => {
                  if (moment(count, 'mm:ss').isBefore(moment(this.timeLimit, 'mm:ss'))) {
                    count =  moment(count, 'mm:ss').add(1, 'second').format('mm:ss')
                    this.remaining = count;
                  }
                }, 1000) 
              } else if (response.data.data.type == 'pause'){
                this.remaining = moment().minute(response.data.data.remaining_minutes).second(response.data.data.remaining_seconds).format('mm:ss')
                clearInterval(this.counter)
              } else {
                this.remaining = moment().minute(response.data.data.minutes).second(response.data.data.seconds).format('mm:ss')
                clearInterval(this.counter)
              }
            }

            if (this.currentState.tab_active == 'Moderated Caucus') {
              this.durHour = response.data.data.duration_hours
              this.durMinute = response.data.data.duration_minutes
              this.durSecond = response.data.data.duration_seconds

              if (response.data.data.type == 'start') {  
                clearInterval(this.counter2)
                this.remaining2 = moment().hour(response.data.data.remaining_duration_hours).minute(response.data.data.remaining_duration_minutes).second(response.data.data.remaining_duration_seconds).format('HH:mm:ss')
                let count2 = this.remaining2 !== null ? this.remaining2 : moment(this.timeLimit2, 'HH:mm:ss').hour(0).minute(0).second(0)
                this.counter2 = setInterval(() => {
                  if (moment(count2, 'HH:mm:ss').isSameOrBefore(moment(this.timeLimit2, 'HH:mm:ss'))) {
                    count2 =  moment(count2, 'HH:mm:ss').add(1, 'second').format('HH:mm:ss')
                    this.remaining2 = count2;
                  }
                }, 1000) 
              } else if (response.data.data.type == 'pause'){
                clearInterval(this.counter2)
                this.remaining2 = moment().hour(response.data.data.remaining_duration_hours).minute(response.data.data.remaining_duration_minutes).second(response.data.data.remaining_duration_seconds).format('HH:mm:ss')
              } else {
                clearInterval(this.counter2)
                this.remaining2 = moment().hour(response.data.data.remaining_duration_hours).minute(response.data.data.remaining_duration_minutes).second(response.data.data.remaining_duration_seconds).format('HH:mm:ss')
              }
            }

            if (this.currentState.tab_active == 'Unmoderated Caucus') {
              this.durHour = response.data.data.duration_hours
              this.durMinute = response.data.data.duration_minutes
              this.durSecond = response.data.data.duration_seconds

              if (response.data.data.type == 'start') {  
                clearInterval(this.counter2)
                this.remaining2 = moment().hour(response.data.data.remaining_duration_hours).minute(response.data.data.remaining_duration_minutes).second(response.data.data.remaining_duration_seconds).format('HH:mm:ss')
                let count2 = this.remaining2 !== null ? this.remaining2 : moment(this.timeLimit2, 'HH:mm:ss').hour(0).minute(0).second(0)
                this.counter2 = setInterval(() => {
                  if (moment(count2, 'HH:mm:ss').isBefore(moment(this.timeLimit2, 'HH:mm:ss'))) {
                    count2 =  moment(count2, 'HH:mm:ss').add(1, 'second').format('HH:mm:ss')
                    this.remaining2 = count2;
                  }
                }, 1000) 
              } else if (response.data.data.type == 'pause'){
                this.remaining2 = moment().hour(response.data.data.remaining_duration_hours).minute(response.data.data.remaining_duration_minutes).second(response.data.data.remaining_duration_seconds).format('HH:mm:ss')
                clearInterval(this.counter2)
              } else {
                this.remaining2 = moment().hour(response.data.data.duration_hours).minute(response.data.data.duration_minutes).second(response.data.data.duration_seconds).format('HH:mm:ss')
                clearInterval(this.counter2)
              }
            } 
          }
        })
    },
  }
}
</script>