<template>
  <div id="simulation-detail-page">
    <section class="tabs-simulation">
      <div class="container">
        <ul class="nav nav-tabs parti-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link" :class="{ active: tab == 'case-detail' }" id="cd-tab" data-bs-toggle="tab" data-bs-target="#cd" type="button" role="tab"
              aria-controls="cd" :aria-selected="[tab == 'case-detail'? true : false]" @click="tab = 'case-detail'">Case Details</button>
          </li>
          <li class="nav-item" role="presentation">
            <button :disabled="roomDetail.status_simulation == 4" class="nav-link" :class="{ active: tab == 'position-paper' }" id="position-paper-tab" data-bs-toggle="tab" data-bs-target="#position-paper"
              type="button" role="tab" aria-controls="position-paper" :aria-selected="[tab == 'position-paper'? true : false]" @click="tab = 'position-paper'">Position Paper</button>
          </li>
          <li class="nav-item" role="presentation">
            <button :disabled="roomDetail.status_simulation == 4 || roomDetail.status_simulation < 3" class="nav-link" :class="{ active: tab == 'simulation' }" id="simulation-tab" data-bs-toggle="tab" data-bs-target="#simulation" type="button"
              role="tab" aria-controls="simulation" :aria-selected="[tab == 'simulation'? true : false]" @click="tab = 'simulation'">Simulation</button>
          </li>
          <li class="nav-item" role="presentation">
            <button :disabled="roomDetail.status_simulation == 4" class="nav-link" :class="{ active: tab == 'chat' }" id="chat-tab" data-bs-toggle="tab" data-bs-target="#chat" type="button" role="tab"
              aria-controls="chat" :aria-selected="[tab == 'chat'? true : false]" @click="tab = 'cat'">Chat</button>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade" :class="{ active: tab == 'case-detail', show: tab == 'case-detail' }" id="cd" role="tabpanel" aria-labelledby="cd-tab">
          <case-detail :room="room" :room2="roomDetail"></case-detail>
        </div>
        <div class="tab-pane fade" :class="{ active: tab == 'position-paper', show: tab == 'position-paper' }" id="position-paper" role="tabpanel" aria-labelledby="position-paper-tab">
          <position-paper :room="room" :room2="roomDetail"></position-paper>
        </div>
        <div class="tab-pane fade" :class="{ active: tab == 'simulation', show: tab == 'simulation' }" id="simulation" role="tabpanel" aria-labelledby="simulation-tab">
          <simulation :room="room" :room2="roomDetail"></simulation>
        </div>
        <div class="tab-pane fade" :class="{ active: tab == 'chat', show: tab == 'chat' }" id="chat" role="tabpanel" aria-labelledby="chat-tab">
          <chat></chat>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CaseDetail from './user/CaseDetail.vue'
import PositionPaper from './user/PositionPaper.vue'
import Simulation from './user/Simulation.vue'
import Chat from './user/Chat.vue'
import cookie from "js-cookie";

export default {
  name: "SimulationUserDetail",
  components: {
    CaseDetail,
    PositionPaper,
    Simulation,
    Chat
  },
  data() {
    return {
      room: {},
      tab: 'case-detail',
      roomDetail: {}
    }
  },
  watch: {
    tab(newVal) {
      console.log(newVal);
      cookie.set('tab', newVal)
    }
  },
  mounted() {
    this.getRoomDetail()
    if (this.$route.hash !== "") {
      switch(this.$route.hash) {
        case "#case-detail":
          this.tab = 'case-detail';
          break;
        case "#participant":
          this.tab = 'participant';
          break;
        case "#simulation":
          this.tab = 'simulation';
          break;
        case "#chat":
          this.tab = 'chat';
          break;
      }
    }

    const tabHistory = cookie.get('tab')
    if (tabHistory !== undefined) {
      this.tab = tabHistory
    }
  },
  methods: {
    getRoomDetail() {
      this.$axios.get(`/master/room/overview_detail/${this.$route.params.slug}`)
        .then(response => {
          this.room = response.data.data
          this.getDetailRoom()
        });
    },
    getDetailRoom() {
      this.$axios.get(`/master/room/${this.room.id}`)
        .then(response => {
          this.roomDetail = response.data.data
          if (this.roomDetail.status_simulation >= 4) {
            this.tab = 'case-detail'
          }
        })
    },
  }
}
</script>

<style scoped>
button:disabled {
  cursor: not-allowed
}
</style>