<template>
  <div class="box-chat">
    <div class="chat-head">
      <div class="d-flex align-items-center">
        <div>
          <img :src="meta.flag" class="img-fluid" width="30"></div>
          <div class="date-info mx-2">{{ meta.name }}</div>
      </div>
    </div>
    <div class="chat-content">
      <template v-for="(message, index) in messages">
        <template v-if="message.sender_id != $store.state.user.id">
          <div class="chat-item left" :key="index">
            <div class="chat-img">
              <img :src="message.sender_avatar" class="img-fluid">
            </div>
            <div class="chat-odd">
              <div class="profile-name dark-blue"> 
                <span>{{ message.sender_name }} </span> | {{ message.sender_country }}
              </div>
              {{ message.message }}
            </div>
            <div class="chat-time">{{ message.message_date | date_only }} {{ message.message_time | time_only }}</div>
          </div>
        </template>
        <template v-else>
          <div class="chat-item right" :key="index">
            <div class="chat-img">
              <img :src="message.sender_avatar" class="img-fluid">
            </div>
            <div class="chat-even">
              {{ message.message }}
            </div>
            <div class="chat-time">{{ message.message_date | date_only}} {{ message.message_time | time_only }}</div>
          </div>
        </template>
      </template>
    </div>
    <div class="chat-footer">
      <form @submit.prevent="sendMessage">
        <div class="row gx-2">
          <div class="col-sm-11">
            <input type="text" class="form-control no-radius" placeholder="Enter Your message" v-model="message" required>
          </div>
          <div class="col-sm-1">
            <button class="btn btn-send w-100" type="submit">Send</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
export default {
  name: "ChatPositionPaper",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    },
    participantId: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      messages: [],
      message: null,
      meta: {}
    }
  },
  watch: {
    room() {
      this.getChat();
    },
    participantId() {
      this.getChat();
    }
  },
  methods: {
    getChat() {
      if (this.participantId > 0) { 
        this.$axios.get(`/simulation/student/position_paper/${this.room.id}/messages`, {
          params: {
            participantId: this.participantId
          }
        }).then(response => {
          this.messages = response.data.data.data
          this.meta = response.data.data.meta
        })
      }
    },
    sendMessage() {
      this.$axios.post(`/simulation/student/position_paper/${this.room.id}/send_message`, {
        participant_id: this.participantId,
        sender_id: this.$store.state.user.id,
        message: this.message,
      }).then(response => {
        console.log(response.data)
        this.getChat();
        this.message = null
      })
    }
  }
}
</script>