<template>
  <div class="container">
    <section class="for-parti">
      <div class="chat-row">
        <div class="chat-info">
          <ul class="nav nav-tabs chat-tabs" id="chatTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="user-tab" data-bs-toggle="tab" data-bs-target="#user"
                type="button" role="tab" aria-controls="user" aria-selected="true">User</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link " id="country-tab" data-bs-toggle="tab" data-bs-target="#country"
                type="button" role="tab" aria-controls="country" aria-selected="false">Country</button>
            </li>
          </ul>
          <div class="box-search-user">
            <input type="text" class="form-control search-uc" placeholder="Search..." v-model="search">
            <div class="btn-icon-search">
              <button class="btn btn-show-pass"><span class="icon-ico-search"></span></button>
            </div>
          </div>
          <div class="tab-content" id="navChatContent">
            <div class="tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="user-tab">
              <div class="chat-list-content">
                <div class="chat-header">Chats</div>
                <ul class="nav nav-tabs chat-tabs" id="chat-userTab" role="tablist">
                  <li class="nav-item" role="presentation" v-for="(data, index) in chats" :key="index">
                    <a href="#" class="nav-link" :id="'active-'+index+'-tab'" data-bs-toggle="tab" :data-bs-target="'#active-'+index"
                      type="button" role="tab" :aria-controls="'active-'+index" aria-selected="true" @click="selectChat(data), data.total_unread_message = 0">
                      <div class="box-user-list">
                        <div class="for-img-profile">
                          <img :src="data.user.student.profile_picture" class="img-fluid">
                        </div>
                        <div class="for-img-profile-info">
                          <div class="chat-for-un">{{data.user.student.name}} {{ data.user.student.alias ? '- ' + data.user.student.alias : '' }}
                            <span v-if="data.user.participant">
                              | {{data.user.participant.name}} 
                            </span>
                            <span v-else>
                              | Master
                            </span>
                          </div>
                          <div class="chat-for-uc">{{data.last_message != null && data.last_message.message.length > 25 ? data.last_message.message.substr(0, 25) + '...' : data.last_message.message }}</div>
                        </div>
                        <div class="ket-time">
                          {{data.last_message.created_at | month_date}}
                          <div class="unread" v-if="data.total_unread_message > 0">{{data.total_unread_message}}</div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <div class="chat-header mt-2">Participants</div>
                  <li class="nav-item" role="presentation" v-for="(data, index) in participants" :key="index">
                    <a href="#" class="nav-link" :id="'part-'+index+'-tab'" data-bs-toggle="tab" :data-bs-target="'#part-'+index"
                      type="button" role="tab" :aria-controls="'part-'+index" aria-selected="true" @click="selectParticipant(data)">
                      <div class="box-user-list">
                        <div class="for-img-profile">
                          <img :src="data.student.profile_picture" onerror="this.src='/img/dummy.png';" class="img-fluid">
                        </div>
                        <div class="for-img-profile-info">
                          <div class="chat-for-un">{{data.student.name}} {{ data.student.alias ? '- ' + data.student.alias : '' }}</div>
                          <div class="chat-for-uc">{{data.participant.name}}</div>
                        </div>
                        <div class="ket-time">
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-pane fade" id="country" role="tabpanel" aria-labelledby="country-tab">
              <div class="chat-list-content">
                <div class="chat-header">Chats</div>
                <ul class="nav nav-tabs chat-tabs" id="chat-userTab" role="tablist">
                  <li class="nav-item" role="presentation" v-for="(data, index) in country_chats" :key="index">
                    <a href="#" class="nav-link" id="united-1-tab" data-bs-toggle="tab" data-bs-target="#united-1"
                      type="button" role="tab" aria-controls="united-1" aria-selected="true" @click="selectChat(data), data.total_unread_message = 0">
                      <div class="box-user-list">
                        <div class="for-img-profile-country">
                          <img src="/img/dummy.png" class="img-fluid">
                        </div>
                        <div class="for-img-profile-info">
                          <div class="chat-for-un">{{data.name}}</div>
                          <div class="chat-for-uc">{{data.last_message != null && data.last_message.message.length > 25 ? data.last_message.message.substr(0, 25) + '...' : data.last_message.message }}</div>
                        </div>
                        <div class="ket-time">
                          {{data.last_message.created_at | month_date}}
                          <div class="unread" v-if="data.total_unread_message > 0">{{data.total_unread_message}}</div>
                        </div>
                      </div>
                    </a>
                  </li>
                  <div class="chat-header mt-1">Participants</div>
                  <li class="nav-item" role="presentation" v-for="(data, index) in countries" :key="index">
                    <a href="#" class="nav-link" :id="'part-'+index+'-tab'" data-bs-toggle="tab" :data-bs-target="'#part-'+index"
                      type="button" role="tab" :aria-controls="'part-'+index" aria-selected="true" @click="selectCountryParticipant(data)">
                      <div class="box-user-list">
                        <div class="for-img-profile">
                          <img src="/img/dummy.png" class="img-fluid">
                        </div>
                        <div class="for-img-profile-info">
                          <div class="chat-for-un">{{data.name}}</div>
                          <div class="chat-for-uc">Online</div>
                        </div>
                        <div class="ket-time">
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="chat-conversation">
          <div class="tab-content" id="navChatContent">
            <div class="tab-pane fade show active" role="tabpanel">
              <div class="box-chat">
                <div v-if="selected_chat">
                  <div class="chat-head">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="img-profile">
                          <img :src="selected_chat.user.student.profile_picture" onerror="this.src='/img/dummy.png';" class="img-fluid img-circle" v-if="selected_chat.user">
                          <img :src="selected_chat.student.profile_picture" onerror="this.src='/img/dummy.png';" class="img-fluid img-circle" v-if="selected_chat.student">
                          <img src="/img/dummy.png" onerror="this.src='/img/dummy.png';" class="img-fluid img-circle" v-if="!selected_chat.user && !selected_chat.student">
                          <div class="ol"></div>
                        </div>
                      </div>
                      <div class="chat-head-name" v-if="selected_chat.user"> {{selected_chat.user.student.name}} {{ selected_chat.user.student.alias ? '- ' + selected_chat.user.student.alias : '' }}
                        <!-- <br><small>{{selected_chat.user.participant.name}}</small> -->
                      </div>
                      <div class="chat-head-name" v-if="selected_chat.student"> {{selected_chat.student.name}} {{ selected_chat.student.alias ? '- ' + selected_chat.student.alias : '' }}
                        <!-- <br><small>{{selected_chat.participant.name}}</small> -->
                      </div>
                      <div class="chat-head-name" v-if="!selected_chat.user && !selected_chat.student"> {{selected_chat.name}}
                        <!-- <br><small>{{selected_chat.participant.name}}</small> -->
                      </div>
                    </div>
                  </div>
                  <div class="chat-content" id="chat-window">
                    <div class="chat-item " :class="data.sender.student.id == id_user ? 'right' : 'left' " v-for="(data, index) in conversations" :key="index">
                      <div class="chat-img"><img :src="data.sender.student.profile_picture" class="img-fluid"></div>
                      <div :class="data.sender.student.id == id_user ? 'chat-even' : 'chat-odd' ">
                        <div class="profile-name" :class="data.sender.student.id == id_user ? '' : ' dark-blue' " v-if="data.sender.student.id != id_user"> 
                          <span>{{data.sender.student.name}} {{ data.sender.student.alias ? '- ' + data.sender.student.alias : '' }}</span> 
                          <span v-if="data.sender.participant"> | {{data.sender.participant.name}}</span>
                          <span v-else> | Master</span>
                        </div>
                        {{data.message}}
                      </div>
                      <div class="chat-time">{{data.created_at | chat_date}}</div>
                    </div>
                  </div>
                  <div class="chat-footer">
                    <div class="row gx-2">
                      <div class="col-sm-9 col-lg-10">
                        <input type="text" class="form-control no-radius" placeholder="Enter Your message" v-on:keyup.enter="sendMessage()" v-model="newMessage">
                      </div>
                      <div class="col-sm-3 col-lg-2">
                        <button class="btn btn-send w-100" @click="sendMessage">Send</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="chat-head">
                    <div class="d-flex align-items-center">
                      <div>
                        <div class="img-profile">
                          <div class="ol"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="chat-content"></div>
                  <div class="chat-opening">
                    <button class="btn start-conv">Start Conversation</button>
                  </div>
                  <div class="chat-footer">
                    <div class="row gx-2">
                      <div class="col-sm-9 col-lg-10">
                        <input type="text" class="form-control no-radius" placeholder="Enter Your message">
                      </div>
                      <div class="col-sm-3 col-lg-2">
                        <button class="btn btn-send w-100">Send</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  data(){
    return {
      id_user: '',
      selected_chat: null,
      room: null,
      participants: [],
      participants_backup: [],
      countries: [],
      countries_backup: [],
      chats: [],
      chats_backup: [],
      conversations: [],
      newMessage: '',
      search: '',
      myCountry: null,
      country_chats: [],
      country_chats_backup: [],
      current_chat_id: null
    }
  },
  methods: {
    getRoomDetail() {
      let _ = this
      _.$axios.get(`/master/room/overview_detail/${_.$route.params.slug}`)
        .then(response => {
          _.room = response.data.data
          _.getParticipants()
          _.getChats()
          _.getCountryChats()
        });
    },
    getParticipants() {
      let _ = this
      _.$axios.get('/chat/participants/' + _.room.id)
        .then(resp => {
          resp.data.data.forEach(elm => {
            if (elm.student.id == _.$store.state.user.id) {
              _.myCountry = elm
            } else if(elm.participant == null) {
              _.participants.push({
                id: elm.id,
                participant: {
                  name: 'Master'
                },
                student: elm.student
              })
            } else {
              _.participants.push(elm)
            }
          });

          _.participants_backup = _.participants

          //LISTEN TO STUDENT PARTICIPANT ID
          window.Echo.leave(`message.${_.room.id}_${_.myCountry.id}`) 
          window.Echo.channel(`message.${_.room.id}_${_.myCountry.id}`)
            .listen('UserToUserSentEvent', (e) => {
              console.log(e)
                _.getChats()
            });

          //LISTEN TO COUNTRY
          window.Echo.leave(`message.${_.room.id}_${_.myCountry.participant.id}`) 
          window.Echo.channel(`message.${_.room.id}_${_.myCountry.participant.id}`)
            .listen('UserToUserSentEvent', (e) => {
                console.log(e)
                _.getCountryChats()
            });

          _.getCountries()
        })
    },
    getCountries() {
      let _ = this
      _.$axios.get('/chat/countries/' + _.room.id)
        .then(resp => {
          _.countries = resp.data.data
          _.countries_backup = _.countries
        })
    },
    getChats() {
      let _ = this
      _.$axios.get('/chat/' + _.room.id + '?type=1')
        .then(resp => {
          _.chats = resp.data.data
          _.chats_backup = _.chats
        })
    },
    getCountryChats() {
      let _ = this
      _.$axios.get('/chat/' + _.room.id + '?type=2')
        .then(resp => {
          _.country_chats = resp.data.data
          _.country_chats_backup = _.country_chats
        })
    },
    selectChat(data) {
      let _ = this
      _.selected_chat = data
      _.conversations = []

      _.$axios.get('chat/detail/' + _.selected_chat.id)
        .then(resp => {
          resp.data.data.forEach(element => 
            _.conversations.push(element)
          );
          setTimeout(() => {
            var elm = document.getElementById('chat-window')
            elm.scrollTop = elm.scrollHeight
          })
        })

      window.Echo.leave(`chat.${_.current_chat_id}`) 
      window.Echo.channel(`chat.${_.selected_chat.id}`)
        .listen('ChatEvent', (e) => {
            if (_.selected_chat != null && e.chat.id_chat == _.selected_chat.id && e.chat.sender.student.id != _.$store.state.user.id) {
              _.conversations.push(e.chat)
              setTimeout(() => {
                var elm = document.getElementById('chat-window')
                elm.scrollTop = elm.scrollHeight
              })
            }

            if (!_.selected_chat.user && !_.selected_chat.student){
              _.getCountryChats()
            } else {
              _.getChats()
            }
        });

      _.current_chat_id = _.selected_chat.id
    },
    selectParticipant(data) {
      let _ = this
      let found = false

      _.chats.forEach(elm => {
        if (elm.user.id == data.id) {
          _.selectChat(elm)
          found = true
        }
      });

      if (!found) {
        _.selected_chat = data
        _.conversations = []
      }
    },
    selectCountryParticipant(data) {
      let _ = this
      let found = false

      _.country_chats.forEach(elm => {
        if (elm.participants == data.id) {
          _.selectChat(elm)
          found = true
        }
      });

      if (!found) {
        _.selected_chat = data
        _.conversations = []
      }
    },
    sendMessage() {
      let _ = this
      if (!_.selected_chat.user && !_.selected_chat.student){
        _.$axios.post('/chat', {
            'id_room': _.room.id,
            'type': 2,
            'id_student_participant_recipient': _.selected_chat.participants ? _.selected_chat.participants : _.selected_chat.id,
            'message': _.newMessage
        }).then((resp) => {
            _.conversations.push(resp.data.data)
            _.getCountryChats()
            setTimeout(() => {
              var elm = document.getElementById('chat-window')
              elm.scrollTop = elm.scrollHeight
            })
        });
      } else {
        _.$axios.post('/chat', {
            'id_room': _.room.id,
            'type': 1,
            'id_student_participant_recipient': _.selected_chat.user ? _.selected_chat.user.id : _.selected_chat.id,
            'message': _.newMessage
        }).then((resp) => {
            _.conversations.push(resp.data.data)
            _.getChats()
            setTimeout(() => {
              var elm = document.getElementById('chat-window')
              elm.scrollTop = elm.scrollHeight
            })
        });
      }
      _.newMessage = '';
    }
  },
  mounted() {
    let _ = this
    _.getRoomDetail()
    _.id_user = _.$store.state.user.id    
  },
  watch: {
    search: function(){
      let _ = this
      _.participants = _.participants_backup
      _.chats = _.chats_backup
      _.countries = _.countries_backup
      _.country_chats = _.country_chats_backup

      if (_.search != '' && _.search != null && _.search != undefined){
        _.participants = _.participants.filter(x => x.participant.name.toLowerCase().includes(_.search.toLowerCase()) || x.student.name.toLowerCase().includes(_.search.toLowerCase()));
        _.chats = _.chats.filter(z => z.user.participant != null && (z.user.student.name.toLowerCase().includes(_.search.toLowerCase()) || z.user.participant.name.toLowerCase().includes(_.search.toLowerCase())));
        _.countries = _.countries.filter(y => y.name.toLowerCase().includes(_.search.toLowerCase())) 
        _.country_chats = _.country_chats.filter(q => q.name.toLowerCase().includes(_.search.toLowerCase())) 
      }
    }
  }
}
</script>