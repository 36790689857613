<template>
  <div class="inner-tabs-simulation">
    <form @submit.prevent="submitAlliance">
      <div class="row align-items-center mb-2">
        <div class="col-lg-6 col-sm-6">
          <div class="row align-items-center mb-3">
            <div class="col-lg-3 col-sm-4">
              <div class="set-text-time no-mb w-100">Alliance Name</div>
            </div>
            <div class="col-lg-9 col-sm-8">
              <input type="text" class="form-control no-radius" v-model="alliance" :class="{ 'is-invalid': $v.alliance.$error }">
              <div v-if="$v.alliance.$error" class="invalid-feedback">
                <span v-if="!$v.alliance.$required">This value is required.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center mb50">
        <div class="col-lg-6 col-sm-6">
          <div class="box-candidate mb-3 mh-1">
            <div class="bc-head">
              <div class="sl">Sponsors List</div>
              <div class="dropdown">
                <button class="btn btn-add-speaker" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  Add Sponsor <span class="icon-ico-plus"></span>
                </button>
                <ul class="dropdown-menu speaker dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton1">
                  <template v-for="(participant, index) in participants">
                    <li :key="index">
                      <button type="button" class="dropdown-item" href="#" @click="addSponsor(participant)">
                        {{ participant.name }}
                      </button>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="bc-content">
              <ul class="nav nav-tabs" id="speakerTab" role="tablist">
                <template v-for="(sponsor, index) in sponsors">
                  <li class="nav-item" role="presentation" :key="index">
                    <a href="#" class="nav-link">{{ sponsor.name }}</a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6">
          <div class="box-candidate mb-3 mh-1">
            <div class="bc-head">
              <div class="sl">Signatories List</div>
              <div class="dropdown">
                <button class="btn btn-add-speaker" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  Add Signator <span class="icon-ico-plus"></span>
                </button>
                <ul class="dropdown-menu speaker dropdown-menu-end"
                  aria-labelledby="dropdownMenuButton1">
                  <template v-for="(participant, index) in participants">
                    <li :key="index">
                      <button type="button" class="dropdown-item" href="#" @click="addSignator(participant)">
                        {{ participant.name }}
                      </button>
                    </li>
                  </template>
                </ul>
              </div>
            </div>
            <div class="bc-content">
              <ul class="nav nav-tabs" id="speakerTab" role="tablist">
                <template v-for="(signator, index) in signatories">
                  <li class="nav-item" role="presentation" :key="index">
                    <a href="#" class="nav-link">{{ signator.name }}</a>
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="ttl-stu">Upload Paper</div>
      <div class="box-upload-inside">
        <div class="drop-zone mb24" :class="{ 'is-invalid': $v.upload.file.$error }" @click="$refs.inputFile.click()">
          <span class="drop-zone__prompt" v-if="upload.isEmpty">
            <img src="/img/icn-upload.svg" class="img-fluid mb-2"><br>
            Click this button to upload file
          </span>
          <div class="drop-zone__thumb" :data-label="upload.filename" v-if="!upload.isEmpty">
            <img src="/img/document.png" class="img-fluid"><br>
          </div>
          <input type="file" name="input-file" ref="inputFile" id="input-file" class="drop-zone__input" @change="preview">
        </div>
        <div class="invalid-feedback" v-if="$v.upload.file.$error">
          <span>This value is required.</span>
        </div>
        <div class="row justify-content-end">
          <div class="col-lg-2 col-sm-4">
            <button type="button" @click="clearContainer" class="btn btn-delete w-100 mm-10">Reset</button>
          </div>
          <div class="col-lg-2 col-sm-4">
            <button type="submit" class="btn btn-orange w-100">Submit</button>
          </div>
        </div>
      </div>
    </form>

    <section class="show-item">
      <div class="container mt-4">
        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Show Entries</label>
              <select class="form-select black" name="per_page" id="per_page" v-model="perPage">
                <option v-for="(page, index) in pageOptions" :key="index" :value="page">{{ page }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Search</label>
              <input type="text" name="search" id="search-participant" class="form-control" placeholder="Search..." v-model="filter">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Table -->
    <b-table
      outlined
      ref="table"
      :items="fetchDraft"
      :fields="fields"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      :empty-text="'There are no records to show'"
      :empty-filtered-text="'There are no records matching your request'"
      class="table-manage"
    >
      <!-- status -->
      <template v-slot:cell(status_text)="data">
        <div class="box-status ongoing" v-if="data.item.status_text == 'Approved'">{{ data.item.status_text }}</div>
        <div class="box-status start" v-if="data.item.status_text == 'Rejected'">{{ data.item.status_text }}</div>
        <div class="box-status aprroval" v-if="data.item.status_text == 'Submitted'">{{ data.item.status_text }}</div>
      </template>
      <!-- Aciton -->
      <template v-slot:cell(action)="data">
        <div class="box-btn-action">
          <a :href="data.item.paper_link" type="button" class="btn btn-icon" target="_blank">
            <span class="icon-ico-download"></span>
            Download
          </a>
        </div>
      </template>
    </b-table>
    <div class="filter-row">
      <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
      <div class="button-filter">
        <ul class="pagination pagination-rounded mb-0">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  required
} from "vuelidate/lib/validators";

export default {
  name: "Assignment",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  watch: {
    room() {
      this.$refs.table.refresh();
      this.getParticipants();
    }
  },
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "alliance_name", label: "ALLIANCE", sortable: true },
        { key: "sponsor_country", label: "SPONSOR", sortable: true },
        // { key: "paper", label: "PAPER", sortable: true },
        { key: "status_text", label: "STATUS", sortable: true },
        { key: "upload_date", label: "UPLOAD", sortable: true },
        'ACTION'
      ],
      alliance: null,
      participants: [],
      sponsors: [],
      signatories: [],
      upload: {
        isEmpty: true,
        filename: null,
        file: null
      },
    }
  },
  validations: {
    alliance: { required },
    upload: {
      file: { required }
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  mounted() {
    if (this.room.id !== undefined) {
      this.$refs.table.refresh();
      this.getParticipants();
    }
  },
  methods: {
    async fetchDraft(ctx) {
      if (this.room.id === undefined) {
        this.totalRows = 0;
        return []
      } else {
        const promise = this.$axios.get(`/student/simulation/${this.room.id}/alliance_draft_resolution_list`, {
          params: {
            page: ctx.currentPage,
            per_page: ctx.perPage,
            search: ctx.filter,
            sortBy: ctx.sortBy,
            sortType: (ctx.sortDesc)? 'asc' : 'desc',
          }
        })

        // Must return a promise that resolves to an array of items
        return promise.then(response => {
          // Pluck the array of items off our axios response
          const items = response.data.data
          this.totalRows = response.data.data.length
          // Must return an array of items or an empty array if an error occurred
          return items || []
        })
      }
    },
    getParticipants() {
      this.$axios.get(`/student/simulation/${this.room.id}/as_list_participant`)
        .then(response => {
          this.participants = response.data.data
        })
    },
    addSponsor(participant) {
      const index = this.sponsors.findIndex(sponsor => sponsor.name == participant.name)
      if (index < 0) {
        this.sponsors.push(participant)
      }
    },
    addSignator(participant) {
      const index = this.signatories.findIndex(sponsor => sponsor.name == participant.name)
      if (index < 0) {
        this.signatories.push(participant)
      }
    },
    preview(event) {
      if(event.target.files[0] !== undefined){
        // const extension = event.target.files[0].name.split(".").pop()
        //const allowedExtention = ['jpg','jpeg','png','gif']
        const fileSize = Math.round((event.target.files[0].size / 1024)); 
        if(fileSize <= 4096){
          this.upload.isEmpty = false
          this.upload.filename = event.target.files[0].name
          this.upload.file = event.target.files[0]
        }
      }
    },
    clearContainer() {
      this.upload.isEmpty = true
      this.upload.filename = null
      this.upload.file = null
      document.getElementById('input-file').value = null
      this.$v.upload.file.$reset()
    },
    async uploadFile() {
      let param = new FormData();
      param.append('file', this.upload.file);
      param.append('folder', 'paper');

      await this.$axios.post('/file_upload', param)
        .then(response => {
          this.upload.filename = response.data.filename
        })
    },
    async submitAlliance() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        // upload file
        await this.uploadFile();
        // submit data 
        this.$axios.post(`/student/simulation/${this.room.id}/submit_assignment`, {
          alliance_name: this.alliance,
          sponsors: this.sponsors.map(sponsor => sponsor.id),
          signatories: this.signatories.map(signator => signator.id),
          paper: this.upload.filename
        })
        .then( async response => {
          Swal.fire("Success", response.data.message, "success");
          this.$refs.table.refresh();
          this.clearContainer();
        })
      }
    }
  }
}
</script>

<style scoped>
.drop-zone__thumb {
  height: 130px !important;
}
.disabled {
  cursor: not-allowed;
}
</style>