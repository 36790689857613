<template>
  <div class="inner-tabs-simulation">
    <div class="ttl-stu">Draft Resolution List</div>
    <section class="show-item">
      <div class="container mt-4">
        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Show Entries</label>
              <select class="form-select black" name="per_page" id="per_page" v-model="perPage">
                <option v-for="(page, index) in pageOptions" :key="index" :value="page">{{ page }}</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="create-form">
              <label class="label-form">Search</label>
              <input type="text" name="search" id="search-participant" class="form-control" placeholder="Search..." v-model="filter">
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Table -->
    <b-table
      outlined
      ref="table"
      :items="fetchDraft"
      :fields="fields"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filter"
      :empty-text="'There are no records to show'"
      :empty-filtered-text="'There are no records matching your request'"
      class="table-manage"
    >
      <!-- status -->
      <template v-slot:cell(status_text)="data">
        <div class="box-status ongoing" v-if="data.item.status_text == 'Approved'">{{ data.item.status_text }}</div>
        <div class="box-status start" v-if="data.item.status_text == 'Rejected'">{{ data.item.status_text }}</div>
        <div class="box-status aprroval" v-if="data.item.status_text == 'Submitted'">{{ data.item.status_text }}</div>
      </template>
      <!-- Aciton -->
      <template v-slot:cell(action)="data">
        <div class="box-btn-action">
          <a :href="data.item.paper_link" type="button" class="btn btn-icon" target="_blank">
            <span class="icon-ico-download"></span>
            Download
          </a>
        </div>
      </template>
    </b-table>
    <div class="filter-row">
      <div class="ft-filter">Showing {{ startRow }} - {{ untilRow }} of {{ totalRows }} list</div>
      <div class="button-filter">
        <ul class="pagination pagination-rounded mb-0">
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"></b-pagination>
        </ul>
      </div>
    </div>

    <div class="row align-items-center mt-5">
      <div class="col-lg-6 col-sm-6">
        <div class="box-candidate mb-3 mh-4">
          <div class="bc-head justify-content-center">
            <div class="bch-ttl">VOTING ON</div>
          </div>
          <div class="bc-content p25" v-if="detailAlliance !== null">
            <div class="time-indicator mb30">
              Draft Resolution - {{ detailAlliance.alliance_name }}
            </div>
            <hr>
            <div class="for-btn-action wrap-mobile mb-3" v-if="statusVote && statusVote.has_vote === false">
              <button class="btn btn-action-speaker mx-1" type="button" @click="popupYes = true">Yes</button>
              <button class="btn btn-action-speaker mx-1" type="button" @click="popupNo = true">No</button>
              <button class="btn btn-action-speaker mx-1" type="button" @click="popupAbstain = true">Abstain</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="box-candidate mb-3 mh-4">
          <div class="bc-head justify-content-center">
            <div class="bch-ttl">OUTCOME</div>
          </div>
          <div class="bc-content p25" v-if="outcome !== null">
            <div class="oi-row">
              <div class="oi-1">In Favour</div>
              <div class="mx-2">:</div>
              <div class="oi-2">{{ outcome.in_favour }}</div>
            </div>
            <div class="oi-row">
              <div class="oi-1">Against</div>
              <div class="mx-2">:</div>
              <div class="oi-2">{{ outcome.againts }}</div>
            </div>
            <div class="oi-row">
              <div class="oi-1">Abstentions</div>
              <div class="mx-2">:</div>
              <div class="oi-2">{{ outcome.absentionts }}</div>
            </div>
            <div class="oi-row">
              <div class="oi-1">Result</div>
              <div class="mx-2">:</div>
              <div class="oi-2">
                <div class="d-flex flex-wrap">
                  <div class="out-status active" v-if="outcome.result === true">Pass</div>
                  <div class="out-status" v-if="outcome.result === false">Failed</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- popup confirm vote yes -->
    <div class="popup-overlay" :class="{ show: popupYes}">
      <div class="inner-delete" :class="{ popup: popupYes}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want vote <b>yes</b> to this simulation ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="popupYes = false">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="voteYes">Yes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- popup confirm vote no -->
    <div class="popup-overlay" :class="{ show: popupNo}">
      <div class="inner-delete" :class="{ popup: popupNo}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want vote <b>no</b> to this simulation ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="popupNo = false">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="voteNo">Yes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- popup confirm vote abstain -->
    <div class="popup-overlay" :class="{ show: popupAbstain}">
      <div class="inner-delete" :class="{ popup: popupAbstain}">
        <div class="title-info">Confirmation</div>
        <p>Are you sure want vote <b>abstain</b> to this simulation ?</p>
        <div class="row g-2">
          <div class="col-6">
            <button type="button" class="btn btn-delete w-100" id="cancel-start-simulation" @click="popupAbstain = false">Cancel</button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-orange w-100" id="submit-start-simulation" @click="voteAbstain">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "RollCallVote",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      sortBy: "created_at",
      sortDesc: false,
      pageOptions: [10, 25, 50, 100],
      fields: [
        { key: "alliance_name", label: "ALLIANCE", sortable: true },
        { key: "sponsor_country", label: "SPONSORS", sortable: true },
        // { key: "paper", label: "PAPER", sortable: true },
        { key: "status_text", label: "STATUS", sortable: true },
        { key: "upload_date", label: "UPLOAD DATE", sortable: true },
        'ACTION'
      ],
      aliances: [],
      detailAlliance: null,
      statusVote: null,
      outcome: null,
      popupYes: false,
      popupNo: false,
      popupAbstain: false
    }
  },
  computed: {
    startRow() {
      if (this.currentPage == 1) {
        return 1
      } else {
        return (this.perPage * (this.currentPage - 1 )) + 1
      }
    },
    untilRow() {
      if ((this.currentPage * this.perPage) <= this.totalRows) {
        return this.currentPage * this.perPage
      } else {
        return this.totalRows
      }
    }
  },
  watch: {
    room() {
      this.$refs.table.refresh();
      this.getCurrentVote();
      this.getOutCome();
    }
  },
  methods: {
    async fetchDraft(ctx) {
      if (this.room.id === undefined) {
        this.totalRows = 0;
        return []
      } else {
        const promise = this.$axios.get(`/student/simulation/${this.room.id}/roll_call_vote_list`, {
          params: {
            page: ctx.currentPage,
            per_page: ctx.perPage,
            search: ctx.filter,
            sortBy: ctx.sortBy,
            sortType: (ctx.sortDesc)? 'asc' : 'desc',
          }
        })

        // Must return a promise that resolves to an array of items
        return promise.then(response => {
          // Pluck the array of items off our axios response
          const items = response.data.data.data
          this.totalRows = response.data.data.meta.total
          // Must return an array of items or an empty array if an error occurred
          return items || []
        })
      }
    },
    getOutCome() {
      this.$axios.get(`/student/simulation/${this.room.id}/current_outcome`)
      .then(response => {
          this.outcome = response.data.data
        })
    },
    getCurrentVote() {
      this.$axios.get(`/student/simulation/${this.room.id}/current_vote`)
      .then(response => {
          this.detailAlliance = response.data.data
          this.getStatusVote();
        })
    },
    voteYes() {
      this.$axios.post(`/student/simulation/${this.room.id}/rc_vote`, {
        assignment_id: this.detailAlliance.assignment_id,
        vote: true
      })
      .then( async response => {
          Swal.fire("Success", response.data.message, "success");
          this.getCurrentVote()
          this.getOutCome()
          this.popupYes = false
        })
    },
    voteNo() {
      this.$axios.post(`/student/simulation/${this.room.id}/rc_vote`, {
        assignment_id: this.detailAlliance.assignment_id,
        vote: false
      })
      .then( async response => {
          Swal.fire("Success", response.data.message, "success");
          this.getCurrentVote()
          this.getOutCome()
          this.popupNo = false
        })
    },
    voteAbstain() {
      this.$axios.post(`/student/simulation/${this.room.id}/rc_vote`, {
        assignment_id: this.detailAlliance.assignment_id,
        is_abstain: true
      })
      .then( async response => {
          Swal.fire("Success", response.data.message, "success");
          this.getCurrentVote()
          this.getOutCome()
          this.popupAbstain = false
        })
    },
    getStatusVote() {
      this.$axios.get(`/student/simulation/${this.room.id}/status_vote`, {
        params: {
          assignment_id: this.detailAlliance.assignment_id
        }
      })
      .then( async response => {
          console.log(response.data)
          this.statusVote = response.data.data
        })
    }
  }
}
</script>