<template>
  <div class="container">
    <section class="for-parti">
      <div class="parti-intro">
        <div class="row">
          <div class="col-lg-6">
            <div class="parti-intro">
              <div class="d-flex">
                <div class="title-intro">Committe</div>
                <div class="mx-2">:</div>
                <div class="intro-parti-desc">
                  <p class="font-medium">{{ header.committee }}</p>
                </div>
              </div>
              <div class="d-flex">
                <div class="title-intro">Subject Topic</div>
                <div class="mx-2">:</div>
                <div class="intro-parti-desc">
                  <p class="font-medium">{{ header.subject_topic }}</p>
                </div>
              </div>
              <div class="d-flex">
                <div class="title-intro">Country Present</div>
                <div class="mx-2">:</div>
                <div class="intro-parti-desc">
                  <p class="font-medium">{{ header.country_present }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="box-flag-top" v-if="header.assigned_country !== undefined">
              <img :src="header.assigned_country.flag" class="img-fluid" style="max-width: 240px; border: 1px solid">
            </div>
          </div>
        </div>
      </div>

      <div class="box-upload-inside" v-if="roomDetail.status_simulation < 3">
        <form @submit.prevent="uploadPosPer">
          <template v-if="roomDetail.status_simulation >= 2">
            <div class="drop-zone mb24" :class="{ 'is-invalid': $v.upload.file.$error }" @click="$refs.inputFile.click()">
              <span class="drop-zone__prompt" v-if="upload.isEmpty">
                <img src="/img/icn-upload.svg" class="img-fluid mb-2"><br>
                Click this button to upload file
              </span>
              <div class="drop-zone__thumb" :data-label="upload.filename" v-if="!upload.isEmpty">
                <img src="/img/document.png" class="img-fluid"><br>
              </div>
              <input type="file" name="input-file" accept=".pdf" ref="inputFile" id="input-file" class="drop-zone__input" @change="preview">
            </div>
          </template>
          <template v-else>
            <div class="drop-zone mb24 disabled">
              <span class="drop-zone__prompt" v-if="upload.isEmpty">
                <img src="/img/icn-upload.svg" class="img-fluid mb-2"><br>
                You can't upload files now
              </span>
            </div>
          </template>
          <div class="invalid-feedback" v-if="$v.upload.file.$error">
            <span>This value is required.</span>
          </div>
          <div class="row justify-content-end">
            <div class="col-lg-2 col-sm-4">
              <button :disabled="roomDetail.status_simulation < 2" type="button" @click="clearContainer" class="btn btn-delete w-100 mm-10">Reset</button>
            </div>
            <div class="col-lg-2 col-sm-4">
              <button :disabled="roomDetail.status_simulation < 2" type="submit" class="btn btn-orange w-100">Submit</button>
            </div>
          </div>
        </form>
      </div>

      <position-paper-list :room="room" ref="posper"></position-paper-list>

      <chat :room="room" :participantId="header.participant_id"></chat>
    </section>
  </div>
</template>

<script>
import PositionPaperList from './PositoinPaperComponent/PositionPaperList.vue';
import Chat from './PositoinPaperComponent/Chat.vue';
import Swal from 'sweetalert2';
import {
  required
} from "vuelidate/lib/validators";

export default {
  name: "PositionPaper",
  components: {
    PositionPaperList,
    Chat
  },
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      roomDetail: {},
      header: {},
      upload: {
        isEmpty: true,
        filename: null,
        file: null
      },
    }
  },
  validations: {
    upload: {
      file: { required }
    }
  },
  watch: {
    room() {
      this.getHeader()
      this.getDetailRoom()
    }
  },
  mounted() {
    if (this.room.id !== undefined) {
      this.getHeader()
    }
  },
  methods: {
    getHeader() {
      this.$axios.get(`/student/simulation/${this.room.id}/show`)
        .then(response => {
          this.header = response.data.data
        })
    },
    getDetailRoom() {
      this.$axios.get(`/master/room/${this.room.id}`)
        .then(response => {
          this.roomDetail = response.data.data
        })
    },
    preview(event) {
      if(event.target.files[0] !== undefined){
        const extension = event.target.files[0].name.split(".").pop()
        const fileSize = Math.round((event.target.files[0].size / 1024)); 
        if(fileSize > 4096 && extension === "pdf"){
          alert('file too large');
          return;
        }
        if(extension !== "pdf"){
          alert('file type not allowed');
          return;
        }
        this.upload.isEmpty = false
        this.upload.filename = event.target.files[0].name
        this.upload.file = event.target.files[0]
      }
    },
    clearContainer() {
      this.upload.isEmpty = true
      this.upload.filename = null
      this.upload.file = null
      document.getElementById('input-file').value = null
      this.$v.upload.file.$reset()
    },
    async uploadFile() {
      let param = new FormData();
      param.append('file', this.upload.file);
      param.append('folder', 'posper');

      await this.$axios.post('/file_upload', param)
        .then(response => {
          this.upload.filename = response.data.filename
        })
    },
    async uploadPosPer() {
      this.$v.$touch()
      if (!this.$v.$invalid) {

        if (this.roomDetail.status_simulation <= 1) {
          Swal.fire("Error", "Simulation Room is still in registration phase", "error");
          return false
        }

        // upload file
        await this.uploadFile();
        // submit data 
        this.$axios.post('/simulation/student/position_paper/submit', {
          filename: this.upload.filename,
          room_id: this.room.id,
          participant_id: this.header.participant_id
        }).then(response => {
          Swal.fire("Success", response.data.message, "success");
          this.clearContainer()
          this.$refs.posper.refreshTable();
        })
      }
    }
  }
}
</script>

<style scoped>
.drop-zone__thumb {
  height: 130px !important;
}
.disabled {
  cursor: not-allowed;
}
button:disabled {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}
</style>