<template>
  <div id="case-detail">
    <section class="detail-desc-room">
      <div class="container">
        <div class="row for-rd">
          <div class="col-lg-6 col-sm-6">
            <img :src="room.banner_url" class="img-fluid w-100">
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="room-desc-detail">
              <h2 class="detail-title">{{ room.subject_topic }}</h2>
              <p v-html="room.case_overview"></p>
              <div class="row ">
                <div class="col-sm-5">
                  <div class="date">Registration Date</div>
                  <div class="date-info mb24">{{ room.registration_date }}</div>
                </div>
                <div class="col-sm-7">
                  <div class="date">Simulation Date</div>
                  <div class="date-info mb24">{{ room.simulation_date }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="room-tabs">
      <div class="container">
        <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-overview-tab" data-bs-toggle="pill" data-bs-target="#pills-overview"
              type="button" role="tab" aria-controls="pills-overview" aria-selected="true">Overview</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-role-tab" data-bs-toggle="pill" data-bs-target="#pills-role" type="button"
              role="tab" aria-controls="pills-role" aria-selected="false">Role-Play</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-guide-tab" data-bs-toggle="pill" data-bs-target="#pills-guide"
              type="button" role="tab" aria-controls="pills-guide" aria-selected="false">Model Guide</button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active for-p" id="pills-overview" role="tabpanel"
            aria-labelledby="pills-overview-tab">
            <h4 class="more-cs mb24">Details</h4>
            <p v-html="room.the_issue"></p>
            <div class="container-iframe" v-if="overview_link">
              <iframe class="responsive-iframe" :src="overview_link"></iframe>
            </div>
          </div>
          <div class="tab-pane fade for-p" id="pills-role" role="tabpanel" aria-labelledby="pills-role-tab">
            <h4 class="more-cs mb24">Role-Play</h4>
            <p></p>

            <div class="accordion" id="accordion-role">
              <template v-for="(role, index) in room.role_list">
                <div class="accordion-item" :key="index">
                  <h2 class="accordion-header" :id="`heading-${index}`">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      :data-bs-target="`#collapse-${index}`" aria-expanded="false" :aria-controls="`collapse-${index}`">
                      {{ role.name }}
                    </button>
                  </h2>
                  <div :id="`collapse-${index}`" class="accordion-collapse collapse" :aria-labelledby="`heading-${index}`"
                    data-bs-parent="#accordion-role">
                    <div class="accordion-body">
                      <p>Description of Role:</p>

                      <p v-html="role.role_description"></p>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="tab-pane fade for-p" id="pills-guide" role="tabpanel" aria-labelledby="pills-guide-tab">
            <h4 class="more-cs mb24">Simulation Guide</h4>
            <p v-html="room.model_guide"></p>
            <div class="container-iframe" v-if="model_guide_link">
              <iframe class="responsive-iframe" :src="model_guide_link"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="more-case">
      <div class="container">
        <h4 class="more-cs mb40">More Cases</h4>
        <div class="row justify-content-center">
          <template v-for="(cases, index) in relateds">
            <div class="col-lg-4 col-sm-6" :key="index">
              <div class="box-cs">
                <div class="img-room">
                  <figure>
                    <router-link :to="`/room/${cases.id}`">
                      <img :src="cases.banner_url" class="img-fluid w-100">
                    </router-link>
                  </figure>
                </div>
                <div class="box-white">
                  <h4 class="cs-head">{{ cases.subject_topic }}</h4>
                  <p v-html="cases.case_overview"></p>
                  <div class="date">Registration Date</div>
                  <div class="date-info mb24">{{ cases.registration_date }}</div>

                  <div class="date">Simulation Date</div>
                  <div class="date-info mb40">{{ cases.simulation_date }}</div>

                  <router-link :to="`/room/${cases.slug}`" class="link-service">
                    View Details &nbsp; <span class="icon-chevron-right"></span>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="for-link">
          <router-link to="/room" class="link-underline">View All Cases</router-link>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  name: "CaseDetail",
  props: {
    room: {
      type: Object,
      default: () => {
        return {}
      } 
    },
    room2: {
      type: Object,
      default: () => {
        return {}
      } 
    }
  },
  data() {
    return {
      relateds: [],
      overview_link: null,
      model_guide_link: null,
    }
  },
  mounted() {
    this.getRelatedRoom()
  },
  watch: {
    room2: {
      handler (newVal) {
        if (newVal) {
          if (newVal.link_url.includes("youtube.com")) {
            const link1 = newVal.link_url.split('?')
            const params = new URLSearchParams(`?${link1[1]}`)
            for (const param of params) {
              if (param[0] === 'v') {
                this.overview_link = `https://www.youtube.com/embed/${param[1]}`
              }
            }
          }
          if (newVal.link_embed_url.includes("youtube.com")) {
            const link2 = newVal.link_embed_url.split('?')
            const params = new URLSearchParams(`?${link2[1]}`)
            for (const param of params) {
              if (param[0] === 'v') {
                this.model_guide_link = `https://www.youtube.com/embed/${param[1]}`
              }
            }
          }
        }
      },
      deep: true
    }
  },
  methods: {
    getRelatedRoom() {
      this.$axios.get('/master/room/more_cases')
        .then(response => {
          this.relateds = response.data.data.more_cases
        })
    }
  }
}
</script>


<style scoped>
.container-iframe {
  display: block;
  align-items: center;
  /* position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  /* position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; */
  width: 650px;
  height: 350px;
}
</style>
